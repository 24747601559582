

.settingsStatisticsContainer {
    border-bottom: 1px solid rgba(112, 112, 112, 0.4);
    padding-bottom: 10px;
}

.settingsStatisticsHeader {
    margin: 0px;
    font-size: 22px;
    margin-bottom: 5px;
    margin-top: 10px;
    color: var(--text-color);
    font-weight: 600;
}

.settingsStatisticsGraphContainer {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
}

.settingsStatisticsGraphLabel {
    align-self: center;
    font-weight: bold;
    font-size: 14px;
    justify-self: start;
    margin: 0px;
    margin-bottom: 3px;
    color: var(--text-color);
    font-weight: 600;
}

.settingsStatisticsGraphLabel:nth-child(2) {
    justify-self: end;
    text-align: end;
}

.settingsStatisticsGraph {
    grid-column: span 2;
    width: 100%;
    height: 25px;
    /* border: 1px solid var(--primary-color); */
    background-color: var(--primary-background-color);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    margin-bottom: 10px;
    overflow: hidden;
}

.settingsStatisticsGraphCorrectBar {
    background-color: var(--primary-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    height: calc(100% - 6px);
    margin: 3px;
    align-items: center;
    justify-items: center;
    display: inline-grid;
    vertical-align: top;
    transition-duration: 300ms;
}

.settingsStatisticsGraphIncorrectBar {
    height: calc(100% - 6px);
    margin: 3px;
    align-items: center;
    justify-items: center;
    display: inline-grid;
    vertical-align: top;
    transition-duration: 300ms;
}

.settingsStatisticsGraphText {
    color: var(--text-color);
    margin: 0px;
    font-size: 12px;
}

.graphTextCorrect {
    color: white;
}

.settingsStatisticsIconWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr 1fr;
    row-gap: 5px;
}

.settingsStatisticsIconContainer {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    width: fit-content;
}

.settingsStatisticsIconContainer:nth-child(2), .settingsStatisticsIconContainer:nth-child(4) {
    justify-self: end;
}

.settingStatisticsIcon {

}

.settingsStatisticsIconText {
    margin: 0px;
    margin-left: 5px;
    font-size: 14px;
    width: fit-content;
    color: var(--secondary-text-color);
}

.settingsStatisticsToggleWrapper {
    display: grid;
    grid-template-columns: auto 1fr auto;
    margin-top: 15px;
}

.settingsStatisticsToggleText {
    margin: 5px 0px;
    color: var(--text-color);
    font-weight: 500;
    font-size: 14px;
    width: fit-content;
}

.settingStatsInfoButton {
    align-self: center;
    margin-left: 5px;
    cursor: pointer;
    padding: 5px 0px;
}

.settingsStatisticsInfoContainer {
    position: absolute;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
    width: 90%;
    padding: 10px;
    background-color: var(--secondary-background-color);
    left: 50%;
    z-index: 100;
    /* display: none; */
}

.settingsStatisticsInfoText {
    margin-top: 0px;
    color: var(--text-color);
}

.settingsStatisticsInfoCloseButton {
    width: 100%;
    height: 35px;
}