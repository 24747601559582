

.spacedModePopupPageWrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;
}

.spacedModePopupBackdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.spacedModePopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
    width: fit-content;
    max-width: calc(100% - 20px);
    max-height: calc(100% - 20px);
    background-color: var(--primary-background-color);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto auto;
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    padding: 10px;
    row-gap: 10px;
    column-gap: 10px;
}

.spacedModePopupSquare {
    background-color: var(--secondary-background-color);
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    padding: 15px 10px;
}

.spacedModePopupTitle {
    text-align: center;
    font-size: 22px;
    color: var(--primary-color);
    margin: 0px;
    font-weight: 600;
}

.spacedModePopupQuantity {
    text-align: center;
    font-size: 32px;
    color: var(--primary-color);
    margin: 5px 0px;
}

.spacedModePopupQuantityTitle {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    margin: 5px 0px;
    color: var(--text-color);
}

.spacedModePopupDate {
    text-align: center;
    font-size: 28px;
    color: var(--primary-color);
    margin: 5px 0px;
}

.spacedModePopupButton {
    padding: 12px;
}