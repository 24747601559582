
.switchToggleContainer {
    padding: 4px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.3);
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    border-radius: 2px;
    background-color: var(--primary-background-color);
    width: 50px;
    height: fit-content;
    border: 0px;
}

.switchToggleIcon {
    height: 18px;
    width: 18px;
    position: relative;
    border-radius: 2px;
    background-color: var(--secondary-background-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}