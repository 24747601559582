

.unauthenticatedPageWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100000;
}

.unauthenticatedBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}

.unauthenticatedContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-background-color);
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    height: fit-content;
    width: calc(95% - 20px);
    max-width: 330px;
    padding: 10px;

}

.unauthenticatedHeader {
    margin: 0px;
    margin-top: 10px;
    color: var(--text-color);
    font-weight: 600;
}

.unauthenticatedBody {
    margin: 20px 0px;
    color: var(--text-color);
}

.unauthenticatedButton {
    width: 100%;
    height: 40px;
    cursor: pointer;
}