

.confirmationPopupPageWrapper, .confirmationPopupPageBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 500;
}

.confirmationPopupPageBackdrop {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
}

.confirmationPopupContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-background-color);
    display: grid;
    grid-auto-flow: row;
    z-index: 2;
    /* height: fit-content; */
    width: calc(95% - 20px);
    max-width: 400px;
    padding: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}

.confirmationPopupHeader {
    /* margin: 10px 0px; */
    margin: 0px;
    margin-top: 5px;
    color: var(--primary-color);
    font-weight: 600;
    height: fit-content;
}

.confirmationPopupText {
    color: var(--text-color);
    white-space: pre-wrap;
    height: fit-content;
}

.confirmationPopupButtonContainer {
    width: 100%;
    justify-self: center;
    align-items: center;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
    margin-top: 10px;
}

.confirmationPopupButton {
    height: 100%;
    width: 100%;
}