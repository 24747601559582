

.flashcardWrapper {
    /* grid-column: span 3; */
    animation: fadeIn 100ms linear normal;
}

@keyframes fadeIn {
    0% {opacity: 0; transform: scale(0.95)}
    100% {opacity: 1; transform: scale(1)}
}

.flashcardContainer {
    background-color: var(--secondary-background-color);
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    display: grid;
    /* grid-template-rows: auto 1fr 50px; */
    position: relative;
    cursor: grab;
    perspective: preserve-3d;
    overflow: hidden;
}

.flashcardCollectionTitle {
    text-align: center;
    color: #808080;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 0px;
    width: 85%;
}

.flashcardQuestionWrapper {
    display: grid;
    grid-auto-flow: row;
    height: fit-content;
    align-self: center;
    /* transform: translateY(-20%); */
    /* overflow: hidden; */
}

.flashcardImage {
    max-width: calc(100% - 60px);
    /* width: 400px; */
    max-height: 30vh;
    background-color: rgb(87, 87, 87);
    justify-self: center;
    margin-bottom: 25px;
}

.flashcardText {
    text-align: center;
    color: var(--text-color);
    padding: 0px 15px;
    white-space: pre-wrap;
    margin: 0px;
    height: fit-content;
    align-self: center;
    font-size: 20px;
    width: 80%;
    justify-self: center;
    font-weight: 600;
}

@media(max-width: 600px) {
    .flashcardImage {
        width: calc(100% - 30px);
    }
}

@media(max-width: 450px) {

    .flashcardCollectionTitle {
        font-size: 13px;
    }
}

.flashcardQALogo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: var(--primary-color);
    opacity: 80%;
    font-size: 18px;
    margin: 0px;
    font-weight: 600;
}

.flashcardStar {
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 30px;
    width: 30px;
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    color: var(--primary-color);
    font-size: 18px;
    cursor: pointer;
}

.flashcardStarIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flashcardMCWrapper {
    margin-top: 10px;
    display: grid;
    grid-template-rows: auto auto auto auto;
    height: fit-content;
    row-gap: 10px;
    padding: 0px 10px;
}

@media(min-width: 500px) {
    .flashcardMCWrapper {
        grid-template-rows: auto auto;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
    }
}

.flashcardMCButton {
    height: 100%;
    width: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    background-color: var(--secondary-background-color);
    color: var(--text-color);
    display: grid;
    grid-template-columns: 40px auto;
    align-items: center;
    margin: auto;
    padding: 15px;
    text-align: left;
    /* width: calc(100% - 20px); */
    cursor: pointer;
    font-size: 14px;
}

.flashcardMCButtonSelected {
    color: white;
    background-color: var(--primary-color);
}

.flashcardMCButton > span {
    font-size: 17px;
    font-weight: bold;
}

.testingSpacedModeText {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    bottom: 20px;
    color: var(--text-color);
    text-align: center;
}

.flashcardLastAnsweredText {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: var(--text-color);
    opacity: 0.7;
    text-align: center;
}