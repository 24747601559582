

.categoriesPageWrapper {
    position: relative;
    max-width: 650px;
    width: calc(100% - 12px);
    height: calc(100% - 10px);
    margin: auto;
    overflow-y: auto;
    padding: 0px 4px;
    padding-top: 10px;
    overflow-x: hidden;
}

.categoriesPageContainer {
    height: fit-content;
    min-height: calc(100% - 50px);
}

.categoriesMainContainer, .categoryContainer {
    background-color: var(--secondary-background-color);
    display: grid;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
}

.categoriesMainContainer {
    margin: 0px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    grid-template-columns: 50px auto 50px;
    cursor: pointer;
}

.categoriesMainText {
    font-size: 16px;
    color: var(--text-color);
}

.categoriesSpacedModeSettingsButton {
    border: 0px;
    background-color: rgba(0, 0, 0, 0);
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
}

.spacedModeSettingsIcon {
    font-size: 20px;
    color: rgb(180, 180, 180);
}

.categoriesExpandCategoriesButton {
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    border: 0px;
    cursor: pointer;
    transform: rotate(-180deg);
    top: 2px;
    transition-duration: 200ms;
}

.categoriesExpandedButtonRotated {
    transform: rotate(0deg);
    top: 0px;
}

.categoriesExpandCategoriesButton::before, .categoriesExpandCategoriesButton::after {
    content: "";
    height: 15px;
    width: 2px;
    border-radius: 2px;
    background-color: rgb(200, 200, 200);
    position: absolute;
    top: 50%;
    left: 50%;
}

.categoriesExpandCategoriesButton::before {
    transform: rotate(45deg) translate(-35%, -50%);
}

.categoriesExpandCategoriesButton::after {
    transform: rotate(-45deg) translate(35%, -50%);
}

.categoriesStartStudyingButton {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 40px;
    border: 0px;
    background-color: var(--primary-color);
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    position: sticky;
    bottom: 10px;
    height: 40px;
    padding: 0px;
    padding-left: 40px;
    width: 100%;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    cursor: pointer;
}

.categoriesStartStudyingArrow {
    font-size: 16px;
}

.categoriesWrapper {
    margin-bottom: 20px;
}

.categoryContainer {
    margin: 10px 10px 0px 40px;
    grid-template-columns: 40px auto;
    width: calc(100% - 40px);
    height: fit-content;
    cursor: pointer;
}

.categoriesText {
    font-size: 14px;
    color: var(--text-color);
}

.checkboxContainer {
    align-self: center;
    justify-self: center;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    padding: auto;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
}

.checkboxContainerSelected {
    background-color: var(--primary-color);
}

.checkboxCheck {
    color: white;
}

.categoriesDownloadProgressTextContainer {
    display: grid;
    justify-items: center;
}

.categoriesDownloadProgressText {
    color: var(--off-text-color);
    font-size: 10px;
    margin: 10px 0px;
}

.categoriesDownloadProgressQuoteText {
    color: var(--text-color);
    margin: 0px;
    margin-top: 5px;
}

.categoriesDownloadProgressSubtext {
    color: var(--off-text-color);
    font-size: 12px;
    max-width: 300px;
    margin: 0px;
    margin-top: 5px;
    text-align: center;
}