

:root {
  /* --primary-color: #0dc59d; */
  /* --primary-color: #c58b0d; */
  /* --primary-color: #1f9181; */
  /* --primary-color: #0db6c5; */
  /* --primary-color: #6233bb; */

  --primary-background-color: #EDEDED;
  --secondary-background-color: #ffffff;
  --text-color: black;
  --secondary-text-color: rgb(29, 29, 29);
  --off-text-color: rgb(120, 120, 120);

  /* --primary-color: #2691BB */
}

::selection {
  color: none;
  background: none;
}

html {
  overflow: hidden;
  background-color: var(--primary-background-color);
}

body {
  font-family: apparat, sans-serif;
  background-color: var(--primary-background-color);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

p, h1, h2, h3, h4, h5 {
  user-select: none;
}

.Toastify {
  position: absolute;
}

.Toastify__toast {
  color: var(--text-color) !important;
}

.toastContainer {
  background: var(--secondary-background-color) !important;
}

.Toastify__progress-bar {
  background: var(--primary-color) !important;
}

.Logo {
  fill: var(--primary-color);
}

.appContainer {
  width: 100%;
  display: grid;
  grid-template-rows: 50px auto;
  grid-template-columns: 325px auto;
}

@media(max-width: 900px) {
  .appContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 50px auto;
  }
}

.navContainer {
  background-color: var(--secondary-background-color);
  height: 50px;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: 1fr 65px;
  grid-column: span 2;
  z-index: 2;
}

@media(max-width: 900px) {
  .navContainer {
    grid-column: 1;
  }
}


.navLogo {
  color: var(--primary-color);
  align-self: center;
  margin-left: 10px;
  font-weight: 600;
  font-size: 20px;
}

.navSettingsButton {
  margin: 0px 10px;
  align-self: center;
  width: 40px;
  height: 40px;
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
  font-size: 20px;
  color: var(--primary-color);
  cursor: pointer;
  display: none;
}

@media(max-width: 900px) {
  .navSettingsButton {
    display: initial;
  }
}

.sidebarSettingsWrapper {
  height: 100%;
  width: 100%;
  background-color: var(--secondary-background-color);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

@media(max-width: 900px) {
  .sidebarSettingsWrapper {
    display: none;
  }
}

.secondaryButton, .primaryButton, .loginButton {
  border: 0px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

.loginButton {
  background-color: var(--secondary-background-color);
  color: var(--text-color);
}

.secondaryButton {
  background-color: var(--secondary-background-color);
  color: var(--text-color);
}

.primaryButton {
  background-color: var(--primary-color);
  color: white;
}

.enlargedImageWrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.enlargedImageBackdrop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  cursor: pointer;
}

.enlargedImageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  height: fit-content;
  width: fit-content;
}

.enlargedImage {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  /* z-index: 12; */
  height: 100%;
  width: calc(100% - 20px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  /* max-width: calc(100% - 20px); */
}

.enlargedImageInitial {
  transform: scale3d(1, 1, 1) !important;
}

.kvfysmfp {
  overflow: visible !important;
}

.mobileSettingsPageWrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: none;
}

@media(max-width: 900px) {
  .mobileSettingsPageWrapper {
    display: initial;
  }
}

.mobileSettingsBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 11;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.mobileSettingsContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 95%;
  width: 100%;
  background-color: var(--secondary-background-color);
  border-radius: 10px 10px 0px 0px;
  z-index: 12;
}

.mobileSettingsCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0);
  border: 0px;
  z-index: 50;
  height: 30px;
  width: 30px;
}

.mobileSettingsCloseButton::before, .mobileSettingsCloseButton::after {
  background-color: var(--primary-color);
}

.closeButton {
  cursor: pointer;
}

.closeButton::before, .closeButton::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 22px;
  width: 2px;
}

.closeButton::before {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.closeButton::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

.enlargedImageCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--primary-color);
  border-radius: 2px;
  border: 0px;
  z-index: 52;
  height: 40px;
  width: 40px;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.enlargedImageCloseButton::before, .enlargedImageCloseButton::after {
  background-color: white;
}