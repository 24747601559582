

.loginContainer {
    position: absolute;
    height: fit-content;
    width: 95%;
    max-width: 350px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-background-color);
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.termsOfServiceContainer {
    max-height: 400px;
    height: 90%;
}

.loginTermsOfServiceWrapper {
    max-height: 270px;
    overflow-y: auto;
    padding: 0px 10px;
}

.loginContainer > p > strong {
    font-weight: 600;
}

.loginLogoText {
    font-size: 26px;
    color: var(--primary-color);
    text-align: center;
}

.loginLabel {
    display: block;
    margin: 5px 10px;
    font-size: 14px;
    color: var(--text-color);
}

.loginInput, .loginSelect {
    display: block;
    margin: 0px 10px;
    margin-bottom: 5px;
    height: 40px;
    padding: 0px;
    padding-left: 10px;
    border: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: calc(100% - 30px);
    font-family: apparat;
    font-size: 14px;
    background-color: var(--primary-background-color);
    border-radius: 2px;
    color: var(--text-color);
}

input::selection, textarea::selection {
    background-color: var(--primary-color);
    color: white;
}

.loginSelect {
    padding-left: 5px;
    width: calc(100% - 20px);
}

@media(max-width: 600px) {
    .loginInput {
        font-size: 16px;
    }
}

.forgotPasswordText {
    font-size: 12px;
    margin-left: 10px;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}

.loginButtonWrapper {
    display: grid;
    margin: 10px;
    margin-top: 15px;
    height: 40px;
    width: calc(100% - 20px);
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.loginButton {
    font-weight: 500;
}

.loginOffline {
    height: 40px;
    width: calc(100% - 20px);
    margin: 10px;
    border: 1px solid var(--primary-color);
    color: var(--primary-color);
    background-color: var(--secondary-background-color);
    border-radius: 2px;
}

@media(max-width: 600px) {
    .loginButton {
        font-size: 12px;
    }
}

.loginButtonLoaderWrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 40px;
    width: calc(100% - 20px);
    margin: 10px;
    margin-top: 15px;
}

.termsOfServiceText {
    margin: 10px;
    max-height: 215px;
    overflow: auto;
    color: var(--text-color);
}

.termsOfServiceBackButton {
    margin: 10px;
    height: 40px;
    width: calc(100% - 20px);
}

.loginTermsOfServiceText {
    display: block;
    margin-bottom: 10px;
    text-align: center;
    font-size: 12px;
    color: var(--text-color);
    text-decoration: underline;
    cursor: pointer;
}

.loginLogo {
    height: 30px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 25px;
    margin-bottom: 10px;
}