

.spacedModeSettingsPageWrapper {
    padding: 10px;
    max-width: 550px;
    width: calc(100% - 20px);
    margin: auto;
    height: 100%;
}

.spacedModeSettingsBackButton {
    background-color: rgba(0, 0, 0, 0);
    color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: 40px 1fr;
    border: 0px;
    background-color: var(--primary-color);
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    height: 40px;
    padding: 0px;
    width: calc(100% - 20px);
    max-width: 170px;
    padding-right: 35px;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    cursor: pointer;
}

.spacedModeSettingsTitle {
    /* text-align: center; */
    font-size: 22px;
    color: var(--text-color);
    font-weight: 600;
}

@media(max-width: 450px) {
    .spacedModeSettingsTitle {
        text-align: center;
    }
}

.spacedModeSettingsText {
    white-space: pre-wrap;
    width: calc(100% - 0px);
    /* max-width: 450px; */
    margin: auto;
    color: var(--text-color);
}

.spacedModeSettingsLabel {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
    color: var(--text-color);
}

.spacedModeSettingsSwitchLabel {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    margin-bottom: 5px;
    color: var(--text-color);
}

.spacedModeSettingsSelect {
    height: 35px;
    width: 100%;
    max-width: 200px;
    padding: 0px 5px;
    border-color: var(--primary-color);
    cursor: pointer;
    /* display: block; */
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--secondary-background-color);
    color: var(--text-color);
    font-size: 12px;
}

.spacedModeSettingsModeContainer {
    max-width: 350px;
}