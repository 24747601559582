

.flashcardPageContainer {
    display: grid;
}

.flashcardPageWrapper {
    max-width: 700px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin-top: 10px;
    display: grid;
    justify-self: center;
    grid-template-columns: 30% 70%;
    grid-template-rows: 45px auto 45px;
    row-gap: 10px;
}

@media(max-width: 500px) {
    .flashcardPageWrapper {
        grid-template-columns: 40% 60%;
    }
}

.flashcardPageBackButton {
    /* font-size: 16px; */
    position: relative;
    margin: 0px;
}

.flashcardPageBackArrow {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    /* font-size: 18px; */
}

.flashcardPageProgressBar {
    grid-column: span 2;
    background-color: var(--secondary-background-color);
    border-radius: 2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    margin-left: 10px;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
}

.mobileProgressText {
    display: none;
}

@media(max-width: 600px) {
    .desktopProgressText {
        display: none;
    }
    
    .mobileProgressText {
        display: initial;
    }
}

.flashcardProgressDivider {
    width: 1px;
    height: 25px;
    align-self: center;
    background-color: var(--primary-color);
    /* opacity: 50%; */
}

.flashcardProgressText {
    margin: 0px;
    align-self: center;
    justify-self: center;
    color: var(--primary-color);
    text-align: center;
}

.flashcardButtonWrapper {
    grid-column: span 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 10px;
}

.flashcardButton {

}

.flashcardLoadingContainer {
    grid-column: span 3;
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}

.spacedModeMessageContainer {
    grid-column: span 3;
    
}

.spacedModeMessageText {
    color: var(--text-color);
}

.flashcardCardWrapper {
    grid-column: span 3;
}